.app .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  
  .app .ql-toolbar {
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom: none;
  }
  
  .ql-editor  { display: block; }
  .raw-editor { display: none; max-width: 40rem; width: 100%; }
  .showRaw .ql-editor  { display: none; }
  .showRaw .raw-editor { display: block; }

  .ql-font-monospace,
  .ql-font span[data-value="monospace"]::before {
    font-family: monospace;
  }
